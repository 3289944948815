 

export default {
    all_products: 'Alle Produkte',
    generate_workbook: 'Workbook generieren',
    download_title: 'Download-Bereich',
    download_description: 'Download der Messeunterlagen',
    filter_placeholder: 'Im Workbook filtern',
    search_placeholder: 'Im Workbook suchen',
    meta: 'Workbook',
    date_title: 'September 2024',
};
