import { createGtm } from '@gtm-support/vue-gtm';
import { defineNuxtPlugin, useRouter, useRuntimeConfig } from '#app';
import type { App as VueApp } from 'vue';

export default defineNuxtPlugin(nuxtApp => {
    const config = useRuntimeConfig();
    let isInitialized = false;
    window.dataLayer = window.dataLayer || [];

    if (config.public.gtmId) {
        const gtagSetup = () => {
            const gtag = function(...args: any) {
                // necessary to use arguments here else the push into the datalayer will not work
                // eslint-disable-next-line
                window.dataLayer?.push(arguments);
            };

            if (window.dataLayer?.[0]) {
                gtag('consent', 'default', {
                    ad_storage: window.dataLayer[0]['Google Analytics'] ? 'granted' : 'denied',
                    analytics_storage: window.dataLayer[0]['Google Analytics'] ? 'granted' : 'denied',
                    ad_user_data: window.dataLayer[0]['Google Analytics'] ? 'granted' : 'denied',
                    ad_personalization: window.dataLayer[0]['Google Analytics'] ? 'granted' : 'denied',
                    matomo_storage: window.dataLayer[0].Matomo ? 'granted' : 'denied',
                    hotjar_storage: window.dataLayer[0].Hotjar ? 'granted' : 'denied',
                    personalization_storage: 'denied',
                    functionality_storage: 'denied',
                    security_storage: 'denied',
                });
            }
        };

        const setupGtm = () => {
            const app = nuxtApp.vueApp as VueApp & { _gtmInitialized?: boolean };
            if (!app._gtmInitialized && config.public.gtmId) {
                app.use(createGtm({
                    id: config.public.gtmId.toString(),
                    defer: false,
                    compatibility: false,
                    enabled: true,
                    debug: false,
                    loadScript: true,
                    vueRouter: useRouter(),
                    ignoredViews: [],
                    trackOnNextTick: false,
                }));
                app._gtmInitialized = true;
            }
        };

        nuxtApp.vueApp.mixin({
            mounted() {
                if (!isInitialized) {
                    isInitialized = true;
                    gtagSetup();
                    setupGtm();
                }
            }
        });
    }
});
