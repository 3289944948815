import { default as _91_46_46_46slug_93xSWuhq546SMeta } from "/codebuild/output/src2852495700/src/intersport-order-frontend/pages/[...slug].vue?macro=true";
import { default as _91_46_46_46category_id_93MtAeGLw2hEMeta } from "/codebuild/output/src2852495700/src/intersport-order-frontend/pages/c/[...category_id].vue?macro=true";
import { default as _91cart_id_93neNx8g4CbTMeta } from "/codebuild/output/src2852495700/src/intersport-order-frontend/pages/cart/[cart_id].vue?macro=true";
import { default as contactQzUcgMI9ifMeta } from "/codebuild/output/src2852495700/src/intersport-order-frontend/pages/contact.vue?macro=true";
import { default as faqdm9M96Svc2Meta } from "/codebuild/output/src2852495700/src/intersport-order-frontend/pages/faq.vue?macro=true";
import { default as homeqBqCcrwZ5aMeta } from "/codebuild/output/src2852495700/src/intersport-order-frontend/pages/home.vue?macro=true";
import { default as imprintcTRsa2UsrGMeta } from "/codebuild/output/src2852495700/src/intersport-order-frontend/pages/imprint.vue?macro=true";
import { default as indexO0MSd8lMXRMeta } from "/codebuild/output/src2852495700/src/intersport-order-frontend/pages/index.vue?macro=true";
import { default as login_45postGArdT7Md5KMeta } from "/codebuild/output/src2852495700/src/intersport-order-frontend/pages/login-post.vue?macro=true";
import { default as additional_45header_45fields9fWfvxJK54Meta } from "/codebuild/output/src2852495700/src/intersport-order-frontend/pages/my-account/additional-header-fields.vue?macro=true";
import { default as addresses1ImkFjtvrDMeta } from "/codebuild/output/src2852495700/src/intersport-order-frontend/pages/my-account/addresses.vue?macro=true";
import { default as branch_45clustersPtcQQoUxZWMeta } from "/codebuild/output/src2852495700/src/intersport-order-frontend/pages/my-account/branch-clusters.vue?macro=true";
import { default as cartsPvdFt1kFh9Meta } from "/codebuild/output/src2852495700/src/intersport-order-frontend/pages/my-account/carts.vue?macro=true";
import { default as condition_45sheetsqzU1EOPe85Meta } from "/codebuild/output/src2852495700/src/intersport-order-frontend/pages/my-account/condition-sheets.vue?macro=true";
import { default as order_45details4jLKKkz4yiMeta } from "/codebuild/output/src2852495700/src/intersport-order-frontend/pages/my-account/order-details.vue?macro=true";
import { default as order_45historyffQXLwRcSmMeta } from "/codebuild/output/src2852495700/src/intersport-order-frontend/pages/my-account/order-history.vue?macro=true";
import { default as preferencesKWEQPXrgTjMeta } from "/codebuild/output/src2852495700/src/intersport-order-frontend/pages/my-account/preferences.vue?macro=true";
import { default as my_45accountk8KcX3Ymj2Meta } from "/codebuild/output/src2852495700/src/intersport-order-frontend/pages/my-account.vue?macro=true";
import { default as _91id_93IsoDy5Sg9XMeta } from "/codebuild/output/src2852495700/src/intersport-order-frontend/pages/p/[id].vue?macro=true";
import { default as privacy_45policyLvbYynyNtSMeta } from "/codebuild/output/src2852495700/src/intersport-order-frontend/pages/privacy-policy.vue?macro=true";
import { default as _91_46_46_46slug_93u6UPV9V5SbMeta } from "/codebuild/output/src2852495700/src/intersport-order-frontend/pages/product-notes/[...slug].vue?macro=true";
import { default as smsq7lznLAdsGMeta } from "/codebuild/output/src2852495700/src/intersport-order-frontend/pages/sms.vue?macro=true";
import { default as _91_46_46_46category_id_93mnPOvC0jNRMeta } from "/codebuild/output/src2852495700/src/intersport-order-frontend/pages/workbook/[...category_id].vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    meta: _91_46_46_46slug_93xSWuhq546SMeta || {},
    component: () => import("/codebuild/output/src2852495700/src/intersport-order-frontend/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "c-category_id",
    path: "/c/:category_id(.*)*",
    component: () => import("/codebuild/output/src2852495700/src/intersport-order-frontend/pages/c/[...category_id].vue").then(m => m.default || m)
  },
  {
    name: "cart-cart_id",
    path: "/cart/:cart_id()",
    meta: _91cart_id_93neNx8g4CbTMeta || {},
    component: () => import("/codebuild/output/src2852495700/src/intersport-order-frontend/pages/cart/[cart_id].vue").then(m => m.default || m)
  },
  {
    name: "contact",
    path: "/contact",
    component: () => import("/codebuild/output/src2852495700/src/intersport-order-frontend/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "faq",
    path: "/faq",
    component: () => import("/codebuild/output/src2852495700/src/intersport-order-frontend/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: "home",
    path: "/home",
    component: () => import("/codebuild/output/src2852495700/src/intersport-order-frontend/pages/home.vue").then(m => m.default || m)
  },
  {
    name: "imprint",
    path: "/imprint",
    component: () => import("/codebuild/output/src2852495700/src/intersport-order-frontend/pages/imprint.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/codebuild/output/src2852495700/src/intersport-order-frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login-post",
    path: "/login-post",
    meta: login_45postGArdT7Md5KMeta || {},
    component: () => import("/codebuild/output/src2852495700/src/intersport-order-frontend/pages/login-post.vue").then(m => m.default || m)
  },
  {
    name: "my-account",
    path: "/my-account",
    meta: my_45accountk8KcX3Ymj2Meta || {},
    component: () => import("/codebuild/output/src2852495700/src/intersport-order-frontend/pages/my-account.vue").then(m => m.default || m),
    children: [
  {
    name: "my-account-additional-header-fields",
    path: "additional-header-fields",
    component: () => import("/codebuild/output/src2852495700/src/intersport-order-frontend/pages/my-account/additional-header-fields.vue").then(m => m.default || m)
  },
  {
    name: "my-account-addresses",
    path: "addresses",
    component: () => import("/codebuild/output/src2852495700/src/intersport-order-frontend/pages/my-account/addresses.vue").then(m => m.default || m)
  },
  {
    name: "my-account-branch-clusters",
    path: "branch-clusters",
    component: () => import("/codebuild/output/src2852495700/src/intersport-order-frontend/pages/my-account/branch-clusters.vue").then(m => m.default || m)
  },
  {
    name: "my-account-carts",
    path: "carts",
    component: () => import("/codebuild/output/src2852495700/src/intersport-order-frontend/pages/my-account/carts.vue").then(m => m.default || m)
  },
  {
    name: "my-account-condition-sheets",
    path: "condition-sheets",
    component: () => import("/codebuild/output/src2852495700/src/intersport-order-frontend/pages/my-account/condition-sheets.vue").then(m => m.default || m)
  },
  {
    name: "my-account-order-details",
    path: "order-details",
    component: () => import("/codebuild/output/src2852495700/src/intersport-order-frontend/pages/my-account/order-details.vue").then(m => m.default || m)
  },
  {
    name: "my-account-order-history",
    path: "order-history",
    component: () => import("/codebuild/output/src2852495700/src/intersport-order-frontend/pages/my-account/order-history.vue").then(m => m.default || m)
  },
  {
    name: "my-account-preferences",
    path: "preferences",
    component: () => import("/codebuild/output/src2852495700/src/intersport-order-frontend/pages/my-account/preferences.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "p-id",
    path: "/p/:id()",
    component: () => import("/codebuild/output/src2852495700/src/intersport-order-frontend/pages/p/[id].vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    component: () => import("/codebuild/output/src2852495700/src/intersport-order-frontend/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "product-notes-slug",
    path: "/product-notes/:slug(.*)*",
    component: () => import("/codebuild/output/src2852495700/src/intersport-order-frontend/pages/product-notes/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "sms",
    path: "/sms",
    component: () => import("/codebuild/output/src2852495700/src/intersport-order-frontend/pages/sms.vue").then(m => m.default || m)
  },
  {
    name: "workbook-category_id",
    path: "/workbook/:category_id(.*)*",
    component: () => import("/codebuild/output/src2852495700/src/intersport-order-frontend/pages/workbook/[...category_id].vue").then(m => m.default || m)
  }
]