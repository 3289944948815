 

export default {
    m_nr: 'M-Nr',
    not_available_products: 'Folgende Produkte sind nicht mehr lieferbar',
    no_longer_available_products: 'Folgende Produkte sind bald nicht mehr lieferbar',
    available_products: 'Folgende Produkte sind lieferbar',
    remove_all: 'Alle Produkte löschen',
    delete_cart: 'Warenkorb löschen',
    cancel_cart: 'Warenkorb abwählen',
    change_item: 'Ändern',
    total: 'Gesamt',
    products: 'Produkte',
    subtotal_upe: 'Zwischensumme (UPE)',
    minus_discount: 'abzüglich Rabatt',
    total_upe: 'Gesamtsumme (UPE)',
    back: 'Überspringen',
    continue: 'Weiter',
    total_uvp: 'GESAMT UVP',
    total_ek: 'GESAMT EK',
    gross_ek: 'Brutto EK',
    net_ek: 'Netto EK',
    pieces: 'Stk',
    upe: 'UPE',
    percent_from: '%Ab',
    status: 'Status',
    price_discounted: 'RABATT',
    total_price_discounted: 'GES. EK',
    order_status_expired: 'Bestell Deadline abgelaufen',
    order_status_almost_over: 'Bestell Deadline bald abgelaufen',
    order_possible: 'Bestellung möglich',
    confirm_delete_item_in_cart: 'Bist du dir sicher, dass du das Produkt löschen möchtest?',
    message_delete_item_in_cart: 'Gelöschte Produkte können nicht wiederhergestellt werden.',
    form_edit_name_title: 'Name ändern',
    confirm_delete_cart: 'Bist du dir sicher, dass du das den Warenkorb löschen möchtest?',
    message_delete_cart: 'Gelöschte Warenkörbe können nicht wiederhergestellt werden',
    shopping_carts: 'Warenkörbe',
    shopping_carts_headline: 'Warenkörbe',
    input_name: 'Warenkorb Name eingeben...',
    create_shopping_cart_error: 'Bitte fügen Sie einen Namen für den Warenkorb ein',
    create_shopping_cart: 'Warenkorb erstellen',
    edit_name: 'Name bearbeiten',
    cart_page_title: 'Warenkorb',
    cart_name: 'Name',
    cart_sum: 'Summe',
    general_presentation: 'Warenkorbübersicht',
    active: 'Aktiv',
    cart_info: 'Warenkorb / Informationen',
    create_new_cart: 'Neuer Warenkorb',
    all_carts: 'Alle Warenkörbe',
    to_cart: 'Zum Warenkorb',
    supplier_summary: 'Lieferantenzusammenfassung',
    decline: 'Abbrechen',
    delete: 'Warenkorb Löschen',
    cancelable: 'Warenkorb Abwählen',
    delete_message: 'Möchten Sie den Warenkorb wirklich löschen?',
    cancelable_message: 'Möchten Sie den Warenkorb wirklich löschen und auf die Konditionen verzichten?',
    is_not_deletable_message: 'Achtung, dieser Warenkorb ist verpflichtend oder schon teilweise freigegeben und kann daher nicht gelöscht werden.',
    error_400: "Der Warenkorbname muss aus maximal 40 Zeichen bestehen.",
    error_403: "Warenkorbname existiert bereits. Bitte vergeben Sie einen anderen Namen.",
    error_409: "Warenkorbname existiert bereits. Bitte vergeben Sie einen anderen Namen.",
    error_600_cart_create: "Der Warenkorbname existiert bereits oder beinhaltet über 40 Zeichen.",
    alternative_address_required: 'Kein Lieferant/Modul ausgewählt',
    alternative_address_required_text: 'Es muss mindestens ein Lieferant/Modul ausgewählt sein',
    alternative_address_info: 'Die verfügbare Adressen können über das Intersport Portal hinzugefügt werden',
    alternative_address_success: 'Lieferadresse geändert',
    alternative_address_success_text: 'Die Lieferadresse wurde erfolgreich geändert',
    created_headline: 'Erstellen',
    created_message: 'Der Warenkorb wurde erfolgreich angelegt.',
    deleted_headline: 'Löschen',
    deleted_message: 'Der Warenkorb wurde erfolgreich gelöscht.',
    legend: 'Legende',
    status_new: 'Neu',
    status_edit: 'In Bearbeitung',
    status_done: 'Erledigt',
    status_headline: 'Statuswechsel',
    status_message: 'Der Warenkorbstatus wurde erfolgreich geändert.',
    total_carts: 'Gesamtsumme Warenkörbe',
    total_quantities: 'Gesamt Stk',
    total_gross_ek: 'Gesamt Brutto EK',
    total_net_ek: 'Gesamt Netto EK',
    total_dealer_margin: '%Ab Durchschnitt',
    cart_selected: 'Warenkorb ausgewählt',
    cart_selected_text: 'Der Warenkorb "{0}" wurde erfolgreich ausgewählt',
    choose_alternative_delivery_address: 'Alternative Lieferadressen wählen',
    change_addresses_for_wee: 'Adressen für Warenendempfänger ändern.',
    open_in_new_tab: 'In neuem Tab öffnen',
    open_in_same_tab: 'Hier öffnen',
    status_closed: 'Freigegeben',
};
